<template>
  <div class="animated fadeIn">
    <div class="wrapper">
      <CCard> </CCard>
    </div>
  </div>
</template>

<script>
export default {
	name: "MySetting",
	data() { 
		return {

		} 
	},
	mounted() {},
	computed: {},
	created() {},
	methods: {},
	watch: {},
};
</script>
